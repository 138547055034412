import React, { useContext } from "react"
import { Link } from "gatsby"
import "./ProjectList.scss"
import { LanguageContext } from "../../contexts/languageContext"
import { translateText } from "../../utils/translateText"


/**
 * ProjectList component.
 *
 * @param {Array of Objects} data Defines the data to display in the component.
 * @param {Object} data[i].image.formats Defines the format of the displayed image.
 * @return {string} ProjectList component.
 */
const ProjectList = ({ data, routePrefix }) => {
  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const googleSheetData = languageContext[2]

  return (
    <div className="projects-list-container">
      <h2 className="title">
        {translateText("Projects", currentLanguage.fullTitle, googleSheetData)}
      </h2>

      <div className="projects-list">
        {data.map(project => {
          const { project_list } = project.node[currentLanguage.label.toLowerCase()].image[0].formats
          const staticImage = project.node[currentLanguage.label.toLowerCase()].image[0].staticImages.project_list
          return (
            <Link
              key={project.node[currentLanguage.label.toLowerCase()].id}
              className="project-content"
              to={`${routePrefix}${project.node[currentLanguage.label.toLowerCase()].route}`}
            >
              <img
                src={`${staticImage[1].src}`}
                alt={project.node[currentLanguage.label.toLowerCase()].image.alternativeText}
                width={project_list[0].width}
                height={project_list[0].height}
              />
              <p className="project-title">{project.node[currentLanguage.label.toLowerCase()].name}</p>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
export default ProjectList
