import React, {useMemo} from "react"
import { EngineeringLayout } from "../../../layouts"
import TopComponent from "../../../components/TopComponent/TopComponent"
import Contact from "../../../components/Contact/Contact"
import ProjectList from "../../../components/ProjectList/ProjectList"
import "./Engineering3.scss"
import gifImage1 from "../../../assets/images/gif-images/gif-cube.svg"
import gifImage2 from "../../../assets/images/gif-images/gif-company.svg"
import Testimonial from "../../../components/Testimonial/Testimonial"
import TestimonialSlider from "../../../components/TestimonialSlider/TestimonialSlider"
import { graphql } from "gatsby"
import { MESSAGE_TYPES } from "../../../constants"
import SEO from "../../../components/Seo"
import { localizeStaticNodes } from "../../../utils/localizeDataNodes"
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter"

const MAX_NUMBER_OF_PROJECTS = 6

const Engineering3 = ({ pageContext: { pageData, lang, testimonials, projectImages, companies }, data, location }) => {
  // const {
  //   allDzemoApiTops,
  //   allDzemoApiProjects,
  //   allDzemoApiTestimonials,
  // } = useMemo(() => localizeDataNodes(data, lang), [data, lang])

  const localizedTops = useMemo(() => localizeStaticNodes(pageData[lang].tops, lang), [pageData, lang])

  return (
    <EngineeringLayout location={location} lang={lang} pageData={pageData} companies={companies}>
      <SEO
        title={capitalizeFirstLetter(pageData[lang].name)}
        canonical={location.pathname}
        data={pageData[lang]}
      />
      <div className="engineering-page-3">
        <section
          style={{
            display: "flex",
            minHeight: "250px",
          }}
        >
          {localizedTops.length > 0 && (
            <TopComponent data={localizedTops} imageFormatName={"normal"} />
          )}
        </section>

        <div className="gif-content-top">
          <img src={gifImage1} alt="gif" width="85" height="72" />
          <img src={gifImage2} alt="gif" width="351" height="79" />
        </div>

        <section className="projects-wrapper">
          <ProjectList
            data={projectImages.slice(0, MAX_NUMBER_OF_PROJECTS)}
            routePrefix={`${lang === "en" ? "/projects" : "/de/projects"}`}
          />
        </section>

        <section
          style={{
            background: "rgba(241, 241, 241, 1)",
            minHeight: "717px",
          }}
        >
          <TestimonialSlider>
            {testimonials.map(testimonial => {
              const { id } = testimonial.node[lang]
              return <Testimonial key={id} data={testimonial.node[lang]} />
            })}
          </TestimonialSlider>
        </section>

        <section
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "250px",
            background: "black",
          }}
        >
          <Contact
            mode="black"
            message={{
              message: "Your message has been sent!",
              type: MESSAGE_TYPES.success,
            }}
          />
        </section>
      </div>
    </EngineeringLayout>
  )
}

export default Engineering3

export const query = graphql`
  query engineering3PageQuery($pageId: Int!) {
    allDzemoApiTops(filter: { en: { page: { id: { eq: $pageId } } } }) {
      nodes {
        id
        en {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
        de {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
      }
    }

    allDzemoApiProjects(
      filter: { en: { featured: { ne: null } } }
      sort: { fields: en___created_at, order: DESC }
    ) {
      nodes {
        en {
          id
          teaserText
          route
          name
          image {
            caption
            alternativeText
            formats {
              project_list {
                height
                width
                url
              }
            }
          }
        }
        de {
          id
          teaserText
          route
          name
          image {
            caption
            alternativeText
            formats {
              project_list {
                height
                width
                url
              }
            }
          }
        }
      }
    }

    allDzemoApiTestimonials(
      filter: { en: { featured: { ne: null } } }
      sort: { fields: en___created_at, order: DESC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            alternativeText
            caption
            size
            formats {
              thumbnail {
                url
                width
                height
              }
            }
            hash
          }
        }
        de {
          id
          body
          name
          image {
            alternativeText
            caption
            size
            formats {
              thumbnail {
                url
                width
                height
              }
            }
            hash
          }
        }
      }
    }
  }
`
